import { lazy } from 'react'
import { CampaignStepperProvider } from 'contexts/campaign-stepper-context'

export const sharedRoutes = [
  {
    to: '/',
    label: 'Dashboard',
    component: lazy(() => import('containers/Home')),
    requiresAuth: true,
    exact: true,
    isNavbar: true,
    contextWrappers: [CampaignStepperProvider]
  },

  {
    to: '/campaign/:campaignId/thank-you',
    component: lazy(() => import('containers/campaigns/ThankYouForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/:id/updates',
    from: '/:userType(shelter|pantry)/campaign/:id/updates',
    component: lazy(() => import('containers/common/CampaignUpdates')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/:id/updates/create',
    from: '/:userType(shelter|pantry)/campaign/:id/updates/add',
    component: lazy(() => import('containers/campaigns/UpdateForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/:id/updates/edit/:updateId',
    from: '/:userType(shelter|pantry)/campaign/:id/updates/edit/:updateId',
    component: lazy(() => import('containers/campaigns/UpdateForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/profile',
    from: '/:userType(shelter|pantry|admin)/profile',
    component: lazy(() => import('containers/common/Profile')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/profile/change-password',
    from: '/:userType(shelter|pantry|admin)/profile/change_password',
    component: lazy(() => import('containers/common/LoginInformation')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/profile/shipping-addresses',
    from: '/:userType(shelter|pantry)/profile/shipping_addresses',
    component: lazy(() => import('containers/shelters/ShippingAddresses')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/profile/documentation',
    from: '/:userType(shelter|pantry)/profile/documentation',
    component: lazy(() => import('containers/common/Documentation')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/profile/documentation/:slug',
    from: '/:userType(shelter|pantry)/profile/documentation/:slug',
    component: lazy(() => import('containers/common/DocumentationCategory')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/reports/wishlist_donations',
    component: lazy(() =>
      import('containers/pantry/reports/WishlistDonationsReport')
    ),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/full-bowls-club',
    from: '/:userType(shelter|pantry)/reports/full_bowls_club',
    component: lazy(() => import('containers/pantry/reports/FullBowlsClub')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/emails',
    from: '/:userType(shelter|pantry)/email/manage_email_list',
    label: 'Emails',
    component: lazy(() => import('containers/emails/SarListEmail')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/emails/add',
    from: '/:userType(shelter|pantry)/email/add',
    component: lazy(() => import('containers/emails/SarListEmailAdd')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/emails/edit',
    from: '/:userType(shelter|pantry)/email/edit',
    component: lazy(() => import('containers/emails/SarListEmailEdit')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/emails/analytics',
    from: '/:userType(shelter|pantry)/email/email_analytics',
    component: lazy(() => import('containers/emails/SarEmailAnalytics')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/emails/analytics/:id',
    component: lazy(() => import('containers/emails/SarEmailAnalyticPerEmail')),
    requiresAuth: true,
    exact: true
  },

  {
    to: '/stories/create',
    from: '/:userType(shelter|pantry)/stories/create',
    component: lazy(() =>
      import('containers/common/SuccessStories/SuccessStoriesForm')
    ),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/stories/edit/:id',
    from: '/:userType(shelter|pantry)/stories/edit/:id',
    component: lazy(() =>
      import('containers/common/SuccessStories/SuccessStoriesForm')
    ),
    requiresAuth: true,
    exact: true
  },
  {
    to: 'https://cuddly.com/partner-buying-program',
    label: 'Shop BOGO',
    isNavbar: true,
    exact: true,
    external: true,
    requiresAuth: ['shelter', 'pantry'],
    component: () => <></>
  },
  {
    to: '/profile/partner_page_settings',
    from: '/:userType(shelter|pantry)/profile/partner_page_settings',
    component: lazy(() => import('containers/common/PartnerPageSettings')),
    requiresAuth: true,
    exact: true
  }
]

export default sharedRoutes
